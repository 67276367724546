import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import styles from './FilterMenuSearchBar.module.scss';

export type FilterMenuSearchBarProps = Omit<
    ComponentProps<'input'>,
    'onChange'
> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSearch?: (value: string, event: React.SyntheticEvent<any>) => void;
};

const FilterMenuSearchBar = forwardRef<
    HTMLInputElement,
    FilterMenuSearchBarProps
>(
    (
        {
            type = 'text',
            placeholder = 'Pesquisar',
            onSearch,
            className,
            ...props
        },
        ref,
    ) => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            onSearch?.(e.target.value, e);
        };

        return (
            <input
                {...props}
                ref={ref}
                type={type}
                placeholder={placeholder}
                onChange={handleChange}
                className={classNames(styles.search, className)}
            />
        );
    },
);

FilterMenuSearchBar.displayName = 'FilterMenuSearchBar';

export { FilterMenuSearchBar };
