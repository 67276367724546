import React, { memo } from 'react';
import { Button } from 'rsuite';
import SavedFilters from '../../pages/IPA/RevisaoPrecos/Components/SavedFilters/SavedFilters';
import { FilterPlaceholder } from '../FilterPlaceholder';
import { InnerFilter } from '../FilterSection';
import { Layout } from '../Layout';
import { SegmentsPlaceholder } from './Components';
import styles from './NewFilterBox.module.scss';
import useNewFilterBox from './useNewFilterBox';

const Checkpill = memo(InnerFilter);

const Placeholder = memo(FilterPlaceholder);

const { Section } = Layout;

const NewFilterBox = () => {
    const {
        data,
        values,
        cattegories,
        loading,
        search,
        onSearch,
        onChange,
        onClean,
        onCleanAll,
        onSelectAll,
        handleConfirmPasteMultipleProducts,
    } = useNewFilterBox();

    return (
        <Section className={styles.wrapper} padded>
            <div className={styles.row}>
                <Checkpill
                    type="check"
                    data={data.productIds ?? []}
                    value={values.productIds}
                    search={search.productIds}
                    isLoading={loading.productIds}
                    onSearch={(query) => onSearch('productIds', query)}
                    onChange={(value) => {
                        onChange('productIds', value);
                    }}
                    onClose={() => onSearch('productIds', '')}
                    onClean={() => onClean('productIds')}
                    // @ts-expect-error
                    placeholder={
                        <Placeholder
                            key="productIds"
                            dataKey="productIds"
                            placeholder="Produtos"
                        />
                    }
                    onConfirmSearch={handleConfirmPasteMultipleProducts}
                    pasteValues
                />
                <Checkpill
                    type="check"
                    data={data.productFamilyIds ?? []}
                    value={values.productFamilyIds}
                    isLoading={loading.productFamilyIds}
                    onSearch={(query) => onSearch('productFamilyIds', query)}
                    onChange={(value) => onChange('productFamilyIds', value)}
                    onClose={() => onSearch('productFamilyIds', '')}
                    onClean={() => onClean('productFamilyIds')}
                    // @ts-expect-error
                    placeholder={
                        <Placeholder
                            key="productFamilyIds"
                            dataKey="productFamilyIds"
                            placeholder="Família"
                        />
                    }
                />
                <Checkpill
                    type="check"
                    data={data.storeIds ?? []}
                    value={values.storeIds}
                    isLoading={loading.storeIds}
                    onSearch={(query) => onSearch('storeIds', query)}
                    onChange={(value) => onChange('storeIds', value)}
                    onClose={() => onSearch('storeIds', '')}
                    onClean={() => onClean('storeIds')}
                    selectAll={{
                        onSelectAll: (checked) => {
                            onSelectAll('storeIds', checked);
                        },
                    }}
                    // @ts-expect-error
                    placeholder={
                        <Placeholder
                            key="storeIds"
                            dataKey="storeIds"
                            placeholder="Lojas"
                        />
                    }
                />
                <Checkpill
                    type="check"
                    data={data.priceType ?? []}
                    value={values.priceType}
                    isLoading={loading.priceType}
                    onChange={(value) => onChange('priceType', value)}
                    onClose={() => onSearch('priceType', '')}
                    onClean={() => onClean('priceType')}
                    searchable={false}
                    // @ts-expect-error
                    placeholder={
                        <Placeholder
                            key="priceType"
                            dataKey="priceType"
                            placeholder="Tipo de Preço"
                        />
                    }
                />
                <Checkpill
                    type="check"
                    data={data.segmentos ?? []}
                    value={values.segmentos}
                    isLoading={loading.segmentos}
                    onSearch={(query) => onSearch('segmentos', query)}
                    onChange={(value) => onChange('segmentos', value)}
                    onClose={() => onSearch('segmentos', '')}
                    onClean={() => onClean('segmentos')}
                    groupBy="role"
                    searchable={false}
                    // @ts-expect-error
                    placeholder={
                        <Placeholder
                            key="segmentos"
                            dataKey="segmentos"
                            placeholder="Segmentação"
                        />
                    }
                />
                <Checkpill
                    type="check"
                    data={data.productBrand ?? []}
                    value={values.productBrand}
                    isLoading={loading.productBrand}
                    onSearch={(query) => onSearch('productBrand', query)}
                    onChange={(value) => onChange('productBrand', value)}
                    onClose={() => onSearch('productBrand', '')}
                    onClean={() => onClean('productBrand')}
                    // @ts-expect-error
                    placeholder={
                        <Placeholder
                            key="productBrand"
                            dataKey="productBrand"
                            placeholder="Marca"
                        />
                    }
                />
                <Checkpill
                    type="check"
                    data={data.supplier ?? []}
                    value={values.supplier}
                    isLoading={loading.supplier}
                    onSearch={(query) => onSearch('supplier', query)}
                    onChange={(value) => onChange('supplier', value)}
                    onClose={() => onSearch('supplier', '')}
                    onClean={() => onClean('supplier')}
                    // @ts-expect-error
                    placeholder={
                        <Placeholder
                            key="segmentos"
                            dataKey="segmentos"
                            placeholder="Fornecedor"
                        />
                    }
                />
                <Button className={styles['clear-button']} onClick={onCleanAll}>
                    Limpar filtros
                </Button>
            </div>

            <div className={styles.row}>
                {cattegories.map(({ value: key, level, name }) => (
                    <Checkpill
                        key={key}
                        type="check"
                        data={data[key] ?? []}
                        value={values[key]}
                        onSearch={(query) => onSearch(key, query)}
                        onChange={(value) => onChange(key, value)}
                        onClose={() => onSearch(key, '')}
                        onClean={() => onClean(key)}
                        isLoading={loading.loadingCattegories}
                        // @ts-expect-error
                        placeholder={
                            <SegmentsPlaceholder
                                level={level}
                                placeholder={name}
                            />
                        }
                    />
                ))}
                <SavedFilters className={styles.row} />
            </div>
        </Section>
    );
};

export default memo(NewFilterBox);
