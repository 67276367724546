import React from 'react';
import { formatNumbersToPtBr } from '../../../../../../../../../utils/FormatNumbers';

import styles from './Highlight.module.scss';
interface HighlightProps {
    value: number;
}
export const Highlight = ({ value }: HighlightProps) => {
    const valueFormat = formatNumbersToPtBr(Math.abs(value), 'decimal');

    return (
        <span
            className={[
                styles.highlight,
                value >= 0 ? styles.positive : styles.negative,
            ].join(' ')}
        >
            {value >= 0 ? `+ ` : `- `} {valueFormat} %
        </span>
    );
};
