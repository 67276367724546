import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React from 'react';
import { Content, Tooltip, Whisper } from 'rsuite';

import SimulacaoData from '../../../../../../../assets/image/simulacao-data.svg';

import moment from 'moment';
import { useSelector } from 'react-redux';
import IconQuery from '../../../../../../../assets/icons/icon_query.svg';
import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';
import { BigNumbers } from '../BigNumbersArea/BigNumbers';

export const ForecastDetails = () => {
    const { outputArea, filtersArea } = useSelector(selectorDemandForecast);
    const { hasSimulation, isSearchDone } = outputArea.calculations;
    const { fromDate } = outputArea.query;

    return (
        <>
            {filtersArea.isCompleted && isSearchDone && !hasSimulation ? (
                <div className="previsao-volume-sem-dados-v2">
                    <WarningRoundedIcon style={{ fontSize: '24px' }} />
                    <p>
                        Não temos dados suficiente para calcular a previsão de
                        demanda desse produto.
                    </p>
                    <span>Busque outro produto ou loja.</span>
                </div>
            ) : !hasSimulation && !isSearchDone ? (
                <div className="simulacao-data-info-v2">
                    <div className="simulacao-data-info-v2-content">
                        <img src={SimulacaoData} alt="Simulação de dados" />
                        <p>
                            Para ver os resultados da simulação,
                            <br /> preencha os dados acima
                        </p>
                    </div>
                </div>
            ) : (
                <Content>
                    <div className="previsao-volume-info">
                        <img alt="ícone de previsão" src={IconQuery} />
                        <h3>
                            Previsão de Demanda de Volume e Receita{' '}
                            <Whisper
                                trigger="hover"
                                placement="top"
                                speaker={
                                    <Tooltip>
                                        <p>
                                            Essa previsão tem margem de erro de
                                            aproximadamente{' '}
                                            <strong>40% </strong>
                                        </p>
                                        Estimativas de demanda estão sujeitas a
                                        erros, visto que o preço não é o único
                                        fator de influência do consumo
                                    </Tooltip>
                                }
                            >
                                <span className="beta-badge">BETA</span>
                            </Whisper>
                        </h3>
                        <p style={{ fontSize: '14px', color: '#747474' }}>
                            Data do cálculo:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {moment(fromDate).format('DD/MM/yyyy')}
                            </span>
                        </p>
                        <div className="confiabilidade-info" />
                    </div>
                    <BigNumbers />
                </Content>
            )}
        </>
    );
};
