import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, FlexboxGrid } from 'rsuite';
import { LoadingSpiner } from '../../../../../../../components/LoadingSpinner';
import { FiltersArea } from '../../Components/FiltersArea/Filters';
import { ForecastDetails } from '../../Components/ForecastDetailsArea/ForecastDetails';
import { Header } from '../../Components/Header/Header';
import { ProductDetails } from '../../Components/ProductDetails/ProductDetails';
import { SimulatorArea } from '../../Components/SimulatorArea/Inputs';
import { TableArea } from '../../Components/TableArea/TableArea';

const PrevisaoDemandaV2 = () => {
    return (
        <Container className="main-container previsao-demanda-page-v2">
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <LoadingSpiner size="md" />
                    <Header />
                    <FiltersArea />
                    <SimulatorArea erroPreco={false} />
                    <ProductDetails />
                    <ForecastDetails />
                    <TableArea />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>
    );
};

export default withRouter(PrevisaoDemandaV2);
