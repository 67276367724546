import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { selectorDemandForecast } from '../../../../../../reducers/previsaoDemanda';
import {
    checkAllSavedScenarios,
    checkSavedScenarios,
    ScenariosProps,
    setSavedScenarios,
} from '../../../../../../reducers/previsaoDemanda/demandForecastSimulations';
import * as service from '../services';

export function useTableActions() {
    const [sortColumn, setSortColumn] = useState('');
    const [orderBy, setOrderBy] = useState<'asc' | 'desc'>('asc');
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const { filtersArea } = useSelector(selectorDemandForecast);

    const dispatch = useDispatch();

    const { dateRange, productId, storesId } = filtersArea.selectedFilters;

    const data = {
        product_ids: productId ? [productId] : null,
        store_ids: storesId.length ? storesId : null,
        from_date: dateRange[0] ? format(dateRange[0], 'yyyy-MM-dd') : null,
        to_date: dateRange[1] ? format(dateRange[1], 'yyyy-MM-dd') : null,
    };

    useQuery({
        queryKey: [
            'saved-scenarios',
            page,
            size,
            sortColumn,
            orderBy,
            { dateRange, productId, storesId },
        ],
        queryFn: () =>
            service.demandForecastSearchScenario(
                {
                    page,
                    size,
                    sort: sortColumn,
                    order: orderBy,
                },
                data,
            ),
        onSuccess(data) {
            const { content, ...pagination } = data;
            dispatch(
                setSavedScenarios({
                    data: content,
                    pagination: { ...pagination, activePage: page },
                }),
            );
        },
    });

    const handleSortColumns = async (column: string, type: 'asc' | 'desc') => {
        setSortColumn(column);
        setOrderBy(type);
    };

    const handleChangePage = (value: number) => {
        setPage(value - 1);
    };

    const handleChangeSize = (value: number) => {
        setSize(value);
    };

    const handleCheck = (
        rowIndex: number,
        checked: boolean,
        rowData: ScenariosProps,
    ) => {
        dispatch(checkSavedScenarios({ index: rowIndex, checked, rowData }));
    };

    const handleCheckAll = (checked: boolean) => {
        dispatch(checkAllSavedScenarios({ checked }));
    };

    return {
        tableHandlers: {
            handleSortColumns,
            handleChangePage,
            handleChangeSize,
            setSortColumn,
            setSortType: setOrderBy,
            handleCheck,
            handleCheckAll,
        },
        tableState: {
            sortColumn,
            orderBy,
            page,
        },
    };
}
