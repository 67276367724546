import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Content, FlexboxGrid } from 'rsuite';
import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';

import { FaChevronDown, FaSave } from 'react-icons/fa';
import { MdDownload } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useHandleScenarios } from '../../hooks/useHandleScenarios';
import { ModalSaveScenario } from '../ModalSaveScenario';
import { ModalUpdateScenario } from '../ModalUpdateScenario';
import styles from './ProductDetails.module.scss';
export const ProductDetails = () => {
    const { filtersArea, outputArea, outputSavedSimulations } = useSelector(
        selectorDemandForecast,
    );

    const params = useParams() as Record<'id', string>;

    const buttonDisable = filtersArea.buttons.getOutput.isDisabled;

    const { showModalSave, showModalUpdate } = outputSavedSimulations.modal;

    const {
        handleGetDemandForecastSimulate,
        closeAllModals,
        handleShowModal,
        handleSaveScenario,
        handleDownloadScenario,
        handleUpdateScenario,
        handleOpenModalSave,
    } = useHandleScenarios();

    const isDisabled = !outputArea.calculations.hasSimulation;

    return (
        <Content className="simulation">
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item
                    colspan={16}
                    className="simulation-selected-items"
                >
                    {filtersArea?.isCompleted && (
                        <>
                            <h3>{filtersArea?.activeFilter?.productLabel}</h3>
                            <p>
                                <span>
                                    {
                                        filtersArea?.activeFilter
                                            ?.storeOrClusterLabel
                                    }
                                </span>
                                <span>{filtersArea?.activeFilter?.date}</span>
                            </p>
                        </>
                    )}
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={8}>
                    <FlexboxGrid justify="end" className="simulation-buttons">
                        <div>
                            <Button
                                disabled={isDisabled}
                                className={styles['icon-button']}
                                onClick={handleDownloadScenario}
                            >
                                <MdDownload />
                            </Button>
                            <Button
                                disabled={isDisabled}
                                className={styles['icon-button']}
                                onClick={() => handleShowModal(params?.id)}
                            >
                                <span className={styles['save-icon']}>
                                    <FaSave />
                                    <FaChevronDown />
                                </span>
                            </Button>
                        </div>

                        <Button
                            size="lg"
                            appearance="primary"
                            onClick={handleGetDemandForecastSimulate}
                            disabled={!filtersArea.isCompleted || buttonDisable}
                        >
                            VISUALIZAR RESULTADOS
                        </Button>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            {showModalSave && (
                <ModalSaveScenario
                    show={showModalSave}
                    title={'Salvar'}
                    onHide={closeAllModals}
                    onClickSave={handleSaveScenario}
                />
            )}
            {showModalUpdate && (
                <ModalUpdateScenario
                    show={showModalUpdate}
                    onClickSave={handleUpdateScenario}
                    onClickSaveNew={handleOpenModalSave}
                    onHide={closeAllModals}
                />
            )}
        </Content>
    );
};
