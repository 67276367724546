type styleFormat = 'currency' | 'decimal' | 'percent';

export const formatNumbersToPtBr = (
    value: number,
    style: styleFormat = 'currency',
) => {
    if (!value) {
        return 0;
    }

    return Intl.NumberFormat('pt-BR', {
        style,
        currency: 'BRL',
        maximumFractionDigits: 2,
    }).format(value);
};
