import classNames from 'classnames';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    LoadingSpinerArea,
    SetupMenu,
    SetupMenuItem,
} from '../../../../../components';
import History from '../../../../../utils/History';
import { SetupIPAConfiguracoesBasicas } from '../SetupIPAConfiguracoesBasicas';
import { SetupIPAPrecoAtacado } from '../SetupIPAPrecoAtacado';
import styles from './SetupIPA.module.scss';
import { SetupIPAForm } from './elements';

const PREFIX_URL = '/administrador/produtos/IPA';

const MENU_ITEMS = {
    'configuracoes-basicas': {
        to: 'configuracoes-basicas',
        label: 'Configurações básicas',
        component: SetupIPAConfiguracoesBasicas,
    },
    'dados-do-infopanel': {
        to: 'dados-do-infopanel',
        label: 'Dados do InfoPanel',
        component: SetupIPAForm,
    },
    'canais-de-venda': {
        to: 'canais-de-venda',
        label: 'Canais de Venda',
        component: SetupIPAPrecoAtacado,
    },
} as const;

const { to: DEFAULT_PATH } = MENU_ITEMS['configuracoes-basicas'];

type SetupIPAProps = RouteComponentProps<Record<string, string>> &
    React.HtmlHTMLAttributes<HTMLDivElement>;

const SetupIPA = ({ className, match, ...props }: SetupIPAProps) => {
    const params = match.params?.[0];

    const activePath = (params || DEFAULT_PATH) as keyof typeof MENU_ITEMS;

    const [selectedMenu, setSelectedMenu] = React.useState(activePath);

    React.useEffect(() => {
        setSelectedMenu(activePath);
    }, [activePath]);

    const MemoMenuItem = React.useMemo(
        () => MENU_ITEMS[selectedMenu].component,
        [selectedMenu],
    );

    if (!params) {
        History.push(`${PREFIX_URL}/${DEFAULT_PATH}`);
        return null;
    }

    return (
        <div className={classNames(styles['setup-ipa'], className)} {...props}>
            <SetupMenu className={styles['setup-ipa__setup-menu']}>
                {Object.entries(MENU_ITEMS).map(([key, { to, label }]) => (
                    <SetupMenuItem key={key} to={`${PREFIX_URL}/${to}`}>
                        {label}
                    </SetupMenuItem>
                ))}
            </SetupMenu>

            <div>
                <MemoMenuItem />
                <LoadingSpinerArea area="setup-ipa" height="100%" size="sm" />
            </div>
        </div>
    );
};

export default withRouter(SetupIPA);
