import React, { useCallback, useMemo, useState } from 'react';
import { MdStars } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { selectorIpaSetupCompetitorsDays } from '../../../../../reducers/reducerIPA/basicConfig/competitorsDays';
import useGerenciadorSort from '../../hooks/useGerenciadorSort';
import {
    BaseQuickActionFilterProps,
    useQuickActionFilter,
} from '../QuickActionFilter';

const useGerenciadorQuickActionFilters = () => {
    const { onSortColumn, initialState: initialSort } = useGerenciadorSort();

    const competitorsDays = useSelector(selectorIpaSetupCompetitorsDays);

    const {
        state,
        handleTogglePriceCPI,
        handleChageLastCpiDays,
        handleChangeMinDateCompetitorPriceSource,
        handleToggleChangedPrice,
        handleSelectFilterLimits,
        handleSelectFilterLimitsClick,
        handleToggleQuickActionFilter,
    } = useQuickActionFilter();

    const [cpiFilter, setCpiFilter] = useState(() => {
        return state.minDateCompetitorPrice.value;
    });

    const priceCpiSkin = useMemo(() => {
        let skin: BaseQuickActionFilterProps['skin'] = 'neutral';
        if (state.competitorPrices.isActive) {
            skin = 'primary';
        }
        if (state.minDateCompetitorPrice.isActive) {
            skin = 'primary';
        }
        return skin;
    }, [state]);

    const lastCpiDays = useMemo(
        () => ({
            placeholder: 'Filtrar preços concorrentes',
            type: 'selectInterval',
            icon: 'MdStore',
            prefixLabel: 'Filtrar preço concorrente dos últimos',
            unitLabel: 'dias',
            menuStyle: { width: '300px' },
            maxValue: competitorsDays?.competitor_days ?? 7,
            onClick: handleTogglePriceCPI,
            onClose: () => handleChageLastCpiDays(cpiFilter),
            onChange: setCpiFilter,
            defaultValue:
                competitorsDays?.competitor_days ??
                state.minDateCompetitorPrice.value,
            skin: priceCpiSkin,
            competitorPriceSources: {
                value: state.minDateCompetitorPrice.competitorPriceSources,
                onChange: handleChangeMinDateCompetitorPriceSource,
            },
        }),
        [
            state.minDateCompetitorPrice,
            priceCpiSkin,
            cpiFilter,
            competitorsDays,
            setCpiFilter,
            handleChageLastCpiDays,
            handleTogglePriceCPI,
            handleChangeMinDateCompetitorPriceSource,
        ],
    );

    const changedPricesSkin = useMemo(() => {
        let skin: BaseQuickActionFilterProps['skin'] = 'neutral';
        if (state.changedPrices.isActive) {
            skin = 'primary';
        }
        return skin;
    }, [state.changedPrices.isActive]);

    const changedPrices = useMemo(
        () => ({
            placeholder: 'Somente preços alterados',
            type: 'button',
            icon: 'MdPriceChange',
            skin: changedPricesSkin,
            onClick: handleToggleChangedPrice,
        }),
        [handleToggleChangedPrice, changedPricesSkin],
    );

    const selectLimitsSkin = useMemo(() => {
        let skin: BaseQuickActionFilterProps['skin'] = 'neutral';
        if (state.brokenLimit.isActive) {
            if (state.brokenLimit.value === true) {
                skin = 'error';
            } else if (state.brokenLimit.value === false) {
                skin = 'primary';
            }
        }
        return skin;
    }, [state.brokenLimit]);

    const selectLimits = useMemo(
        () => ({
            placeholder: 'Filtrar limites',
            type: 'select',
            icon: 'MdReport',
            skin: selectLimitsSkin,
            options: [
                {
                    id: 'UNBROKEN_LIMITS',
                    value: false,
                    label: 'Somente limites não quebrados',
                },
                {
                    id: 'BROKEN_LIMITS',
                    value: true,
                    label: 'Somente limites quebrados',
                },
            ],
            onSelect: handleSelectFilterLimits,
            onClick: handleSelectFilterLimitsClick,
        }),
        [
            handleSelectFilterLimits,
            handleSelectFilterLimitsClick,
            selectLimitsSkin,
        ],
    );

    const derrivedProductsSkin = useMemo(() => {
        const skin: BaseQuickActionFilterProps['skin'] = 'neutral';
        if (state.isProductMaster.isActive) {
            return 'primary';
        }
        return skin;
    }, [state.isProductMaster.isActive]);

    const onClickDerrivedProducts = useCallback(() => {
        if (state.isProductMaster.isActive) {
            onSortColumn(initialSort.type, initialSort.orderBy);
        } else {
            onSortColumn('productFamilyMaster', 'desc');
        }
        handleToggleQuickActionFilter('isProductMaster');
    }, [handleToggleQuickActionFilter, onSortColumn]);

    const derrivedProducts = useMemo(
        () => ({
            placeholder: 'Filtrar produtos derivados',
            type: 'button',
            icon: <MdStars />,
            onClick: onClickDerrivedProducts,
            skin: derrivedProductsSkin,
        }),
        [derrivedProductsSkin, onClickDerrivedProducts],
    );

    const filters = useMemo(
        () => ({
            lastCpiDays,
            changedPrices,
            selectLimits,
            derrivedProducts,
        }),
        [lastCpiDays, changedPrices, selectLimits, derrivedProducts],
    );

    return filters;
};

export default useGerenciadorQuickActionFilters;
