import React from 'react';
import Header from '../../../../../../../../components/Header';

import { MdArrowBack } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import styles from './HeaderSavedSimulations.module.scss';

interface HeaderSavedSimulationsProps {
    labelToBack: string;
    urlToBack: string;
    title: string;
    subtitle?: string;
}
export const HeaderSavedSimulations = ({
    title,
    subtitle,
    urlToBack,
    labelToBack,
}: HeaderSavedSimulationsProps) => {
    const history = useHistory();

    const goBack = () => {
        history.push('/ipa/simulacoes-analises/previsao-demanda-v2/');
    };

    return (
        <>
            <div className={styles['header-saved-simulation']}>
                <Link to={urlToBack} className={styles.back}>
                    <MdArrowBack className={styles.icon} />
                    <span>{labelToBack}</span>
                </Link>
                <Button
                    id="simulate-scenario-results"
                    appearance="primary"
                    className={styles.button}
                    onClick={goBack}
                >
                    fazer nova simulação
                </Button>
            </div>

            {/* @ts-ignore */}
            <Header title={title} subtitle={subtitle} />
        </>
    );
};
