import { ModalAlterarPrecoAtacadoPropsSchemaProps } from '../../../../../components/ModalAlterarPrecoAtacado/ModalAlterarPrecoAtacado';
import { PrecoAtacadoDatapointsState } from '../../../../../reducers/PrecoAtacado/datapoints';
import { PrecoAtacadoPaginationState } from '../../../../../reducers/PrecoAtacado/pagination';
import { PrecoAtacadoSelectedDatapointsState } from '../../../../../reducers/PrecoAtacado/selectedDatapoints';
import { PrecoAtacadoSortState } from '../../../../../reducers/PrecoAtacado/sort';
import {
    GET_ITEMS_PRECO_ATACADO_PARAMS,
    POST_CHANGE_MASSIVE_PRICE_DATA,
} from './services';

export namespace PrecoAtacadoChangeMassivePrice {
    export type Params = {
        formData: ModalAlterarPrecoAtacadoPropsSchemaProps;
        selectedDatapoints: PrecoAtacadoSelectedDatapointsState;
        filters: Partial<Record<string, string[]>>;
        datapoints: PrecoAtacadoDatapointsState;
    };
    export type Return = POST_CHANGE_MASSIVE_PRICE_DATA;
}

export const GET_CHANGE_MASSIVE_PRICE_MODEL = ({
    formData,
    selectedDatapoints,
    datapoints,
    filters,
}: PrecoAtacadoChangeMassivePrice.Params): PrecoAtacadoChangeMassivePrice.Return => {
    const { excludedIds, selectedIds, selectedAll } = selectedDatapoints;

    const selectedIdsFromDatapoints = datapoints
        .filter((item) => {
            return selectedIds.includes(item._id);
        })
        .map((item) => item.productsToBePricedId);

    const excludedIdsFromDatapoints = datapoints
        .filter((item) => {
            return excludedIds.includes(item._id);
        })
        .map((item) => item.productsToBePricedId);

    return {
        ...formData,
        selectedAll,
        filters,
        products: {
            selectedIds: selectedIdsFromDatapoints,
            excludedIds: excludedIdsFromDatapoints,
        },
    };
};

namespace GetPrecoAtacadoDatapoints {
    export type Params = {
        sort: PrecoAtacadoSortState;
        pagination: PrecoAtacadoPaginationState;
        filters: Partial<Record<string, string[]>>;
    };
    export type Return = GET_ITEMS_PRECO_ATACADO_PARAMS;
}

export const GET_PRECO_ATACADO_DATAPOINTS_MODEL = (
    params: GetPrecoAtacadoDatapoints.Params,
): GetPrecoAtacadoDatapoints.Return => {
    const { sort, pagination, filters } = params;

    return {
        filters,
        page: pagination.activePage,
        size: pagination.displayLength,
        sort: `${sort.type},${sort.orderBy}`,
    };
};
