import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import Modal from 'rsuite/lib/Modal';
import { z } from 'zod';
import { RootState } from '../../@types/RootState';
import { queryClient } from '../../App';
import { Exception } from '../../data';
import { errorMessages as error } from '../../lib';
import { POST_CHANGE_MASSIVE_PRICE } from '../../pages/IPA/Estrategia/pages/PrecoAtacado/services';
import { GET_CHANGE_MASSIVE_PRICE_MODEL } from '../../pages/IPA/Estrategia/pages/PrecoAtacado/utils';
import { selectorPrecoAtacadoDatapoints } from '../../reducers/PrecoAtacado/datapoints';
import { selectorPrecoAtacadoFiltersValue } from '../../reducers/PrecoAtacado/filters/values';
import { CLOSE_WHOLESALE_MODAL } from '../../reducers/PrecoAtacado/modals';
import { selectorPrecoAtacadoSelectedDatapoints } from '../../reducers/PrecoAtacado/selectedDatapoints';
import { ButtonPrimary } from '../ButtonPrimary';
import { Callout } from '../Callout';
import { InputBox } from '../InfoInput';
import { InputAddon } from '../InputAddon';
import { InputCurrency } from '../InputCurrency';
import { InputGroup } from '../InputGroup';
import { LoadingSpinerArea } from '../LoadingSpinner';
import styles from './ModalAlterarPrecoAtacado.module.scss';

const schema = z.object({
    triggerCount: z
        .number({ required_error: error.required_error })
        .positive({ message: error.positive_value_error }),
    discountPercentage: z
        .number({ required_error: error.required_error })
        .positive({ message: error.positive_value_error })
        .max(99.99, { message: 'O desconto não pode ser 100%' }),
});

export type ModalAlterarPrecoAtacadoPropsSchemaProps = z.infer<typeof schema>;

const ModalAlterarPrecoAtacado = () => {
    const dispatch = useDispatch();

    const isOpen = useSelector((state: RootState) => {
        return state.precoAtacadoReducer.modals.CHANGE_MASSIVE_PRICE;
    });

    const selectedDatapoints = useSelector(
        selectorPrecoAtacadoSelectedDatapoints,
    );

    const datapoints = useSelector(selectorPrecoAtacadoDatapoints);

    const filters = useSelector(selectorPrecoAtacadoFiltersValue);

    const form = useForm<ModalAlterarPrecoAtacadoPropsSchemaProps>({
        defaultValues: {
            triggerCount: 1,
            discountPercentage: 0,
        },
        mode: 'onBlur',
        resolver: zodResolver(schema),
    });

    const handleClose = useCallback(() => {
        form.reset();
        dispatch(CLOSE_WHOLESALE_MODAL('CHANGE_MASSIVE_PRICE'));
    }, [dispatch]);

    const handleSubmit = useCallback(
        async (formData: ModalAlterarPrecoAtacadoPropsSchemaProps) => {
            const ERROR_MESSAGE = 'Erro ao alterar preços.';

            const model = GET_CHANGE_MASSIVE_PRICE_MODEL({
                formData,
                selectedDatapoints,
                datapoints,
                filters,
            });

            try {
                const res = await POST_CHANGE_MASSIVE_PRICE(model);

                if (res.status !== 202) throw new Exception(ERROR_MESSAGE);

                Alert.success('Preços alterados com sucesso.');

                queryClient.invalidateQueries(['ipa/wholesale/datapoints']);

                dispatch(CLOSE_WHOLESALE_MODAL('CHANGE_MASSIVE_PRICE'));

                return res;
            } catch {
                throw Alert.error(ERROR_MESSAGE);
            }
        },
        [selectedDatapoints, filters, dispatch, datapoints],
    );

    return (
        <Modal
            className={classNames(styles.modal, 'info-modal')}
            onHide={handleClose}
            show={isOpen}
        >
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Modal.Header>
                    <Modal.Title>Alterar preço atacado do produto</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.modal__body}>
                    <p className="font-size-100-regular">
                        Altere o gatilho ou o valor do desconto dos produtos
                        selecionados
                    </p>
                    <div className={styles.input_container}>
                        <Controller
                            control={form.control}
                            name="triggerCount"
                            render={({
                                field: { onChange, ...field },
                                fieldState: { error },
                            }) => (
                                <InputBox className={styles.trigger_count}>
                                    <InputBox.Label htmlFor={field.name}>
                                        Gatilho
                                    </InputBox.Label>
                                    <div>
                                        <InputGroup>
                                            <InputAddon>≥</InputAddon>
                                            <InputCurrency
                                                id={field.name}
                                                allowNegative={false}
                                                precision="0"
                                                thousandSeparator="."
                                                onChangeEvent={(_e, _m, v) => {
                                                    onChange(v);
                                                }}
                                                {...field}
                                            />
                                        </InputGroup>
                                        <span>UN</span>
                                    </div>
                                    <InputBox.Error message={error?.message} />
                                </InputBox>
                            )}
                        />
                        <Controller
                            control={form.control}
                            name="discountPercentage"
                            render={({
                                field: { onChange, ...field },
                                fieldState: { error },
                            }) => (
                                <InputBox>
                                    <InputBox.Label htmlFor={field.name}>
                                        Desconto
                                    </InputBox.Label>
                                    <InputGroup
                                        className={styles.discount_percentage}
                                    >
                                        <InputCurrency
                                            id={field.name}
                                            allowNegative={false}
                                            precision="1"
                                            decimalSeparator=","
                                            onChangeEvent={(_e, _m, v) => {
                                                onChange(v);
                                            }}
                                            {...field}
                                        />
                                        <InputAddon>%</InputAddon>
                                    </InputGroup>
                                    <InputBox.Error message={error?.message} />
                                </InputBox>
                            )}
                        />
                    </div>
                    <Callout className={styles.callout}>
                        <Callout.Title>Atenção!</Callout.Title>
                        <Callout.Description>
                            Todos os produtos selecionados serão alterados
                        </Callout.Description>
                    </Callout>
                </Modal.Body>
                <Modal.Footer className={styles.modal__footer}>
                    <ButtonPrimary
                        type="reset"
                        theme="ghost"
                        onClick={handleClose}
                    >
                        fechar
                    </ButtonPrimary>
                    <ButtonPrimary
                        type="submit"
                        disabled={form.formState.isSubmitting}
                    >
                        salvar
                    </ButtonPrimary>
                </Modal.Footer>
            </form>
            <LoadingSpinerArea />
        </Modal>
    );
};

export default ModalAlterarPrecoAtacado;
