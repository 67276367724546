import React from 'react';
import CurrencyInput from 'react-currency-input';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Content, FlexboxGrid, Input, InputGroup } from 'rsuite';
import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';
import { handleEnableButtonMain } from '../../../../../../../reducers/previsaoDemanda/filterArea';
import { setNewValue } from '../../../../../../../reducers/previsaoDemanda/inputArea';
import { clearOutputData } from '../../../../../../../reducers/previsaoDemanda/outputArea';
import { useFilters } from '../../hooks/useFilters';

interface SimulatorAreaProps {
    erroPreco: boolean;
}

export const SimulatorArea = ({ erroPreco }: SimulatorAreaProps) => {
    const { simulatorArea } = useSelector(selectorDemandForecast);
    const { getDemandForecastInputsValue } = useFilters();

    const dispatch = useDispatch();

    const calculateProductPrice = (newPrice: string) => {
        const value = newPrice.replace(',', '.');
        dispatch(setNewValue({ newprice: +value }));
    };

    return (
        <Content className="page-campos-simulacao">
            <FlexboxGrid align="bottom" className="page-title">
                <h3>Resultado separado por loja</h3>
                <p>
                    Veja o resultado da simulação para cada uma das lojas
                    selecionadas
                </p>
            </FlexboxGrid>

            <FlexboxGrid
                className="campanhas-filtros"
                style={{ maxWidth: '700px' }}
            >
                <FlexboxGrid.Item
                    componentClass={Col}
                    // lg={3}
                    md={7}
                    sm={24}
                    xs={24}
                    colspan={12}
                >
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <InputGroup
                                className={`${erroPreco ? 'error' : ''}`}
                            >
                                <InputGroup.Addon>R$</InputGroup.Addon>
                                <CurrencyInput
                                    className="rs-input"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    precision="2"
                                    allowEmpty
                                    value={simulatorArea.inputs.newPrice}
                                    onChangeEvent={(e, value) =>
                                        calculateProductPrice(value)
                                    }
                                    onBlur={(e) => {
                                        const value = e.target.value.replace(
                                            ',',
                                            '.',
                                        );
                                        getDemandForecastInputsValue(
                                            Number(value),
                                        );
                                        dispatch(
                                            handleEnableButtonMain({
                                                buttonName: 'getOutput',
                                                isDisabled: false,
                                            }),
                                        );
                                        dispatch(clearOutputData());
                                    }}
                                    onFocus={(e) =>
                                        dispatch(
                                            handleEnableButtonMain({
                                                buttonName: 'getOutput',
                                                isDisabled: true,
                                            }),
                                        )
                                    }
                                />
                            </InputGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <p className="simulacao-input-label">Preço</p>
                            {erroPreco ? (
                                <p className="simulacao-input-error">
                                    Campo obrigatório
                                </p>
                            ) : null}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xsHidden>
                    <div className="divider" />
                </FlexboxGrid.Item>

                {/* competitividade */}
                <FlexboxGrid.Item
                    componentClass={Col}
                    md={7}
                    sm={12}
                    xs={24}
                    style={{ marginRight: '16px' }}
                >
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            {simulatorArea.inputs.competitiveness ? (
                                <InputGroup>
                                    <CurrencyInput
                                        className="rs-input"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        precision="2"
                                        allowEmpty
                                        value={
                                            simulatorArea.inputs.competitiveness
                                        }
                                        disabled
                                    />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            ) : (
                                <InputGroup>
                                    <Input disabled value="--" />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            )}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <p className="simulacao-input-label">
                                Competitividade
                            </p>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>

                {/* margem */}
                <FlexboxGrid.Item componentClass={Col} md={7} sm={12} xs={24}>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            {simulatorArea.inputs.margin ? (
                                <InputGroup>
                                    <CurrencyInput
                                        className="rs-input"
                                        name="from"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        precision="2"
                                        allowEmpty
                                        value={simulatorArea.inputs.margin}
                                        disabled
                                    />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            ) : (
                                <InputGroup>
                                    <Input disabled value="--" />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            )}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <p className="simulacao-input-label">Margem</p>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Content>
    );
};
