import React, { MouseEvent, useState } from 'react';
import { Modal } from 'rsuite';
import { ModalProps } from 'rsuite/lib/Modal';
import { ButtonPrimary } from '../../ButtonPrimary';
import { FilterMenutextArea } from '../FilterMenutextArea/FilterMenutextArea';
import styles from './FilterPasteModal.module.scss';

const { Body, Footer } = Modal;

type FilterPasteModalProps = ModalProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onConfirm: (value: string, event: React.SyntheticEvent<any>) => void;
    onSearch?: (query: string, e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FilterPasteModal = ({
    show,
    onHide,

    onConfirm,
    onSearch,
}: FilterPasteModalProps) => {
    const [value, setValue] = useState('');

    const handleChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
        onSearch?.(e.target.value, e as never);
    };

    const handleConfirm = (e: MouseEvent) => {
        onConfirm(value, e);
    };

    return (
        <Modal className={styles.modal} show={show} onHide={onHide}>
            <Body>
                <h2 className="font-size-100-regular">
                    Escreva ou cole uma lista de códigos de produto
                </h2>
                <FilterMenutextArea
                    value={value}
                    rows={5}
                    placeholder="Escreva ou cole uma lista de códigos de produto separados por espaço ou pelos caracteres , ; /  "
                    onChange={handleChangeValue}
                    autoFocus
                />
            </Body>
            <Footer>
                <div className={styles.footer}>
                    <ButtonPrimary
                        type="button"
                        size="small"
                        theme="ghost"
                        onClick={onHide}
                    >
                        Cancelar
                    </ButtonPrimary>
                    <ButtonPrimary
                        type="button"
                        size="small"
                        onClick={handleConfirm}
                    >
                        Aplicar
                    </ButtonPrimary>
                </div>
            </Footer>
        </Modal>
    );
};
