import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Content } from 'rsuite';
import { PageProps } from '../../../../../../../@types';
import { useHandleScenarios } from '../../hooks/useHandleScenarios';
import { HeaderSavedSimulations } from '../SavedSimulations/Header';

export type HeaderProps = PageProps;

export const Header = () => {
    const params = useParams() as Record<'id', string>;
    const history = useHistory();

    const { generateScenarioName } = useHandleScenarios();
    const { subtitle, title } = generateScenarioName();

    return (
        <>
            {params?.id ? (
                <>
                    <HeaderSavedSimulations
                        urlToBack="/ipa/simulacoes-analises/previsao-demanda-v2/lista"
                        labelToBack="Voltar para Previsões salvas"
                        title={title}
                        subtitle={subtitle}
                    />
                </>
            ) : (
                <Content className="no-margin-bottom page-header">
                    <div className="grupo-titulo">
                        <h3>Previsão de Demanda</h3>
                        <Button
                            style={{
                                marginLeft: '8px',
                                backgroundColor: '#F5F9FE',
                                borderRadius: '6px',
                            }}
                            appearance="link"
                            onClick={() =>
                                history.push(
                                    '/ipa/simulacoes-analises/previsao-demanda-v2/lista',
                                )
                            }
                        >
                            Ver simulações salvas
                        </Button>
                    </div>
                </Content>
            )}
        </>
    );
};
