import { format } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Checkbox,
    CheckPicker,
    Content,
    DateRangePicker,
} from 'rsuite';
import DateRangePickerPill from '../../../../../../../components/DateRangePickerPill';
import {
    FilterSeparator,
    SelectPill,
} from '../../../../../../../components/Filters';
import { useDebounce } from '../../../../../../../hooks';
import { useSearchParams } from '../../../../../../../hooks/useSearchParams';
import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';
import { dateLocale } from '../../../../../../ISA/InfoPanel/OnDemand/utils';
import { useFilters } from '../../hooks/useFilters';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { setInputsValue } from '../../../../../../../reducers/previsaoDemanda/inputArea';
import { setOutputs } from '../../../../../../../reducers/previsaoDemanda/outputArea';
import { setScenario } from '../../../../../../../reducers/previsaoDemanda/outputSavedSimulations';
import { demandForecastGetScenarioById } from '../../services';
import styles from './filter.module.scss';

export const dateShortcutNextDays = [
    {
        label: 'Próximos 7 dias',
        value: [moment().toDate(), moment().add(7, 'days').toDate()],
    },
    {
        label: 'Próximos 15 dias',
        value: [moment().toDate(), moment().add(15, 'days').toDate()],
    },
    {
        label: 'Próximos 30 dias',
        value: [moment().toDate(), moment().add(30, 'days').toDate()],
    },
];

const selectOptions = [
    {
        label: 'Loja',
        value: 'STORE',
    },
    // {
    //     label: 'Cluster',
    //     value: 'CLUSTER',
    // },
];

export const FiltersArea = () => {
    const { beforeToday } = DateRangePicker;

    const params = useParams() as Record<'id', string>;
    const dispatch = useDispatch();
    const [selectMode, setSelectMode] = useState('STORE');
    const { setParam, clearParams, deleteParam, getParam } = useSearchParams();
    const [searchProduct, setSearchProduct] = useState(
        getParam('productId') || '',
    );

    const { filtersArea } = useSelector(selectorDemandForecast);

    const {
        listProducts,
        listStores,
        handleSetFilter,
        clearFilters,
        handleClearDatePicker,
        generateSelectedItemDetails,
        isListStoresLoading,
    } = useFilters();

    const { isLoading: isListProductsLoading } = useQuery({
        queryKey: ['demand-forecast-products', searchProduct],
        retry: true,
        keepPreviousData: true,
        queryFn: () => listProducts(searchProduct),
        onSuccess: (data) => {
            generateSelectedItemDetails(data);
        },
    });

    useQuery({
        queryKey: ['demand-forecast-get-scenario', params?.id],
        retry: true,
        keepPreviousData: true,
        queryFn: () => demandForecastGetScenarioById(params?.id),
        onSuccess: async (data) => {
            if (data) {
                const { from_date, to_date, product_id, store_ids } =
                    data.query;

                setSearchProduct(product_id);
                dispatch(
                    setOutputs({
                        simulation: data.simulations,
                        output: data.output,
                        query: data.query,
                        scenario: data.scenario,
                    }),
                );

                const input = {
                    ...data.input,
                    value: data.input.base_price,
                    base_price: data.input.new_price || data.input.base_price,
                };

                dispatch(setInputsValue({ inputs: input }));

                dispatch(
                    setScenario({
                        ...data.scenario,
                        scenarioName: data.scenario.scenario_name,
                    }),
                );

                handleSetFilter({
                    name: 'productId',
                    filterValue: product_id,
                    clearData: false,
                });

                handleDateRangePicker(
                    [
                        new Date(`${from_date}T00:00:00.175`),
                        new Date(`${to_date}T00:00:00.175`),
                    ],
                    false,
                );

                handleSetFilter({
                    name: 'storesId',
                    filterValue: store_ids,
                    clearData: false,
                });

                generateSelectedItemDetails();
            }
        },
        enabled: !!params?.id,
    });

    const refToDatePicker = useRef<{ handleClean: () => void }>();

    const debounce = useDebounce(500);

    const getPlaceholder = () => {
        if (!selectMode) return 'Selecione uma opção';

        if (selectMode === 'STORE') return 'Selecione as lojas';

        return 'Selecione os clusters';
    };

    const handleDateRangePicker = (value: Date[], clearData = true) => {
        if (value[0] && value[1]) {
            setParam(
                'filterDate',
                format(value[0], 'yyyy/MM/dd').concat(
                    '-',
                    format(value[1], 'yyyy/MM/dd'),
                ),
            );
            handleSetFilter({
                name: 'dateRange',
                filterValue: value,
                clearData,
            });
        } else if (value.length === 0) {
            deleteParam('filterDate');
        }
    };

    const onCleanAll = () => {
        setSelectMode('STORE');
        refToDatePicker?.current?.handleClean();
        clearParams();
        clearFilters();
    };

    const isCheckedAllStores =
        filtersArea.selectedFilters?.storesId.length > 0 &&
        filtersArea.selectedFilters.storesId?.every((storeId: any) =>
            filtersArea?.list.stores.some(
                (item: any) => item.store_id === storeId,
            ),
        );

    useEffect(() => {
        if (!params?.id) {
            onCleanAll();
        }
    }, [params?.id]);

    return (
        <Content className={styles['filters-area-content']}>
            <div className={styles['inner-div']}>
                <div>
                    <div className={styles['filter-selection-wrapper']}>
                        <div className={styles['div-title']}>Produto</div>
                        <SelectPill
                            id="select-picker-pill"
                            placeholder="Selecione um produto"
                            name="produto"
                            filter={false}
                            value={filtersArea?.selectedFilters?.productId}
                            data={filtersArea?.list?.products}
                            onSelect={(value: any, item: any) => {
                                handleSetFilter({
                                    name: 'productId',
                                    filterValue: value,
                                    item,
                                });
                                setParam('productId', value);
                            }}
                            onSearch={(e) =>
                                debounce(() => setSearchProduct(e))
                            }
                            onClean={() => {
                                handleSetFilter({
                                    name: 'productId',
                                    filterValue: '',
                                });
                                deleteParam('productId');
                                setSearchProduct('');
                            }}
                            locale={{
                                noResultsText: isListProductsLoading
                                    ? 'Carregando produtos...'
                                    : 'Nenhum item encontrado',
                                searchPlaceholder: 'Pesquisar',
                            }}
                        />
                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        <div className={styles['div-title']}>Loja</div>
                        <SelectPill
                            id="select-picker-pill"
                            placeholder="Selecione uma opção"
                            type="select"
                            name="loja"
                            filter={false}
                            searchable={false}
                            cleanable={false}
                            value={selectMode}
                            data={selectOptions}
                            onSelect={(value: any) => {
                                setSelectMode(value);
                            }}
                        />

                        <FilterSeparator />
                        {selectMode === 'STORE' && (
                            <CheckPicker
                                id="select-picker-pill"
                                style={{ maxWidth: '300px' }}
                                cleanable
                                searchable
                                placeholder={getPlaceholder()}
                                type="select"
                                name="loja"
                                filter={false}
                                value={filtersArea?.selectedFilters.storesId}
                                data={filtersArea.list.stores}
                                onSelect={(value) => {
                                    handleSetFilter({
                                        name: 'storesId',
                                        filterValue: value,
                                    });
                                    setParam('storesId', value.join(','));
                                }}
                                onClean={() => {
                                    handleSetFilter({
                                        name: 'storesId',
                                        filterValue: '',
                                    });
                                    deleteParam('storesId');
                                }}
                                locale={{
                                    noResultsText: isListStoresLoading
                                        ? 'Carregando lojas...'
                                        : 'Nenhum item encontrado',
                                    searchPlaceholder: 'Pesquisar lojas',
                                }}
                                renderExtraFooter={() => (
                                    <div
                                        className={
                                            styles['render-extra-footer']
                                        }
                                    >
                                        <Checkbox
                                            block
                                            indeterminate={false}
                                            checked={isCheckedAllStores}
                                            onChange={(
                                                value: any,
                                                checked: boolean,
                                            ) => {
                                                const nextValue = checked
                                                    ? filtersArea?.list.stores.map(
                                                          (item: {
                                                              value: string;
                                                          }) => item.value,
                                                      )
                                                    : [];

                                                handleSetFilter({
                                                    name: 'storesId',
                                                    filterValue: nextValue,
                                                });
                                            }}
                                        >
                                            Selecionar todas as lojas
                                        </Checkbox>
                                    </div>
                                )}
                                onSearch={(search) =>
                                    debounce(() => listStores([search]))
                                }
                            />
                        )}
                    </div>

                    <div className={styles['filter-selection-wrapper']}>
                        <div className={styles['div-title']}>Período</div>
                        {/* @ts-ignore */}
                        <DateRangePickerPill
                            block
                            cleanable
                            classNameValue={
                                filtersArea.selectedFilters.dateRange.length
                                    ? 'date-range-has-value'
                                    : ''
                            }
                            renderValue={(value: Date[]) => (
                                <>
                                    <span>
                                        {format(value[0], 'dd/MM/yyyy')}
                                    </span>
                                    {' a '}
                                    <span>
                                        {format(value[1], 'dd/MM/yyyy')}
                                    </span>
                                </>
                            )}
                            value={filtersArea.selectedFilters.dateRange}
                            onChange={handleDateRangePicker}
                            onOk={handleDateRangePicker}
                            onClean={handleClearDatePicker}
                            appearance="default"
                            placeholder="Selecione um período"
                            format="DD/MM/YYYY"
                            locale={dateLocale}
                            ranges={dateShortcutNextDays}
                            disabledDate={beforeToday()}
                            ref={refToDatePicker}
                        />
                    </div>
                </div>
                <Button
                    className={styles['btn-clear-filters']}
                    onClick={onCleanAll}
                >
                    Limpar filtros
                </Button>
            </div>
        </Content>
    );
};
