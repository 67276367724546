import React from 'react';
import { useSelector } from 'react-redux';
import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';
import { Elasticity } from '../Charts/Elasticity';

import * as utils from '../../../../../../CalculadoraPrevisaoDemanda/utils';

import { formatNumbersToPtBr } from '../../../../../../../utils/FormatNumbers';
import { Indicator } from '../../../../../../CalculadoraPrevisaoDemanda/Components';
import { ForecastNumbers } from '../../../../../../CalculadoraPrevisaoDemanda/Components/ForecastNumbers';
import styles from './BigNumbers.module.scss';

export const BigNumbers = () => {
    const { outputArea } = useSelector(selectorDemandForecast);
    const { basePriceScenario, newPriceScenario, scenariosVariation } =
        outputArea.output;

    return (
        <div className={styles['first-chart-container']}>
            <Elasticity />

            <div className={styles['forecast-container']}>
                <div className={styles.div1}>
                    <ForecastNumbers.Root title="preço">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {utils.formatNumberToCurrency(
                                newPriceScenario?.price,
                                2,
                            )}
                        </ForecastNumbers.MainValue>
                        <Indicator
                            value={utils.formatIndicatorValue(
                                scenariosVariation?.price,
                            )}
                        />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {utils.formatNumberToCurrency(
                                basePriceScenario?.price,
                                2,
                            )}
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>

                <div className={styles.div2}>
                    <ForecastNumbers.Root title="Competitividade">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {newPriceScenario?.competitiveness
                                ? utils.formatLocaleNumber(
                                      utils.formatIndicatorValue(
                                          newPriceScenario?.competitiveness,
                                      ),
                                      1,
                                  )
                                : 0}
                            %
                        </ForecastNumbers.MainValue>
                        <Indicator
                            type="pp"
                            value={utils.formatIndicatorValue(
                                scenariosVariation?.competitiveness,
                            )}
                        />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {basePriceScenario?.competitiveness
                                ? utils.formatLocaleNumber(
                                      utils.formatIndicatorValue(
                                          basePriceScenario?.competitiveness,
                                      ),
                                      1,
                                  )
                                : 0}
                            %
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>

                <div className={styles.div3}>
                    <ForecastNumbers.Root title="margem do produto">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {newPriceScenario?.margin
                                ? utils.formatLocaleNumber(
                                      utils.formatIndicatorValue(
                                          newPriceScenario?.margin,
                                      ),
                                      1,
                                  )
                                : 0}
                            %
                        </ForecastNumbers.MainValue>
                        <Indicator
                            type="pp"
                            value={utils.formatIndicatorValue(
                                scenariosVariation?.margin,
                            )}
                        />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {basePriceScenario?.margin
                                ? utils.formatLocaleNumber(
                                      utils.formatIndicatorValue(
                                          basePriceScenario?.margin,
                                      ),
                                      1,
                                  )
                                : 0}
                            %
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>

                <div className={styles.div4}>
                    <ForecastNumbers.Root title="PREVISÃO DE VENDAS (UN)">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {formatNumbersToPtBr(
                                newPriceScenario.demandForecast,
                                'decimal',
                            )}
                        </ForecastNumbers.MainValue>
                        <Indicator
                            value={utils.formatIndicatorValue(
                                scenariosVariation?.demandForecast,
                            )}
                        />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {formatNumbersToPtBr(
                                basePriceScenario?.demandForecast,
                                'decimal',
                            )}
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>

                <div className={styles.div5}>
                    <ForecastNumbers.Root title="PREVISÃO DE RECEITA (R$)">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {utils.formatNumberToCurrency(
                                newPriceScenario.revenueForecast,
                                2,
                            )}
                        </ForecastNumbers.MainValue>
                        <Indicator
                            value={utils.formatIndicatorValue(
                                scenariosVariation?.revenueForecast,
                            )}
                        />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {utils.formatNumberToCurrency(
                                basePriceScenario?.revenueForecast,
                                2,
                            )}
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>

                <div className={styles.div6}>
                    <ForecastNumbers.Root title="PREVISÃO LUCRO (R$)">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {utils.formatNumberToCurrency(
                                newPriceScenario.profitForecast,
                                2,
                            )}
                        </ForecastNumbers.MainValue>
                        <Indicator
                            value={utils.formatIndicatorValue(
                                scenariosVariation?.profitForecast,
                            )}
                        />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {utils.formatNumberToCurrency(
                                basePriceScenario?.profitForecast,
                                2,
                            )}
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>
            </div>
        </div>
    );
};
