export const generateElasticity = (elasticity: number | null) => {
    if (!elasticity) {
        return {
            classification: 'Sem dados',
            description: 'Sem dados',
        };
    }

    switch (true) {
        case elasticity >= 0 && elasticity <= 1:
            return {
                classification: 'Inelástico',
                description:
                    'Variações de preço não impactam nas vendas do produto',
            };
        case elasticity > 1 && elasticity <= 1.5:
            return {
                classification: 'Pouco Elástico',
                description:
                    'Variações de preços podem impactar nas vendas do produto',
            };
        case elasticity > 1.5 && elasticity <= 3:
            return {
                classification: 'Elástico',
                description:
                    'Pequenas variações de preço impactam nas vendas do produto',
            };
        case elasticity > 3:
            return {
                classification: 'Muito elástico',
                description:
                    'Pequenas variações de preço, causam grande impacto nas vendas do produto',
            };
        default:
            return {
                classification: 'Inelástico',
                description:
                    'Variações de preço não impactam nas vendas do produto',
            };
    }
};
