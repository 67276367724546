import React from 'react';
import { useSelector } from 'react-redux';
import { FlexboxGrid } from 'rsuite';
import { InfoTable, Layout } from '../../../../../../../components';
import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';
import {
    OutputSimulation,
    Query,
} from '../../../../../../../reducers/previsaoDemanda/outputArea';
import { formatNumbersToPtBr } from '../../../../../../../utils/FormatNumbers';
import { generateElasticity } from '../../utils/generateElasticity';
import { CellWrapper } from './Components/CustomCell/CellWrapper';
import { Highlight } from './Components/Highlight/Highlight';
import styles from './TableArea.module.scss';

const DATA_KEY = 'productsToBePricedId';

type QueryProps = Query & {
    storeId: string;
};

type Simulation = {
    output: OutputSimulation;
    query: QueryProps;
};

export const TableArea = () => {
    const { outputArea, filtersArea } = useSelector(selectorDemandForecast);

    const sort = {
        type: DATA_KEY,
        orderBy: 'asc',
    };

    const { Column, HeaderCell, Cell } = InfoTable;

    const hasElasticity = outputArea.calculations.hasElasticity;

    if (!(filtersArea.isCompleted && outputArea.calculations.hasSimulation)) {
        return null;
    }

    return (
        <Layout.Section className={styles['section-table']}>
            <FlexboxGrid className={styles['text-header']}>
                <h3>Resultado separado por loja</h3>
                <FlexboxGrid.Item>
                    <p>
                        Veja o resultado da simulação para cada uma das lojas
                        selecionadas
                    </p>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <InfoTable
                data={outputArea.simulation}
                dataKey={DATA_KEY}
                className={styles.table}
                loading={false}
                minHeight={50}
                sortColumn={sort.type}
                // sortType={sort.orderBy}
                headerHeight={34}
                rowHeight={62}
                // onSortColumn={handleSortColumn}
                // pagination={memoPagination}
            >
                {/* @ts-ignore */}
                <Column sortable flexGrow={2} resizable>
                    <HeaderCell>Loja</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="query"
                    >
                        {(rowData: Simulation) => {
                            return (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>
                                        {rowData.query?.storeId}
                                    </div>
                                    <div
                                        className={styles['secondary-content']}
                                    >
                                        <span>{rowData.query?.client}</span>
                                    </div>
                                </CellWrapper>
                            );
                        }}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={120} resizable>
                    <HeaderCell>Elasticidade</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="elasticity"
                    >
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>
                                    {hasElasticity
                                        ? formatNumbersToPtBr(
                                              (rowData.output?.elasticity ||
                                                  0) * -1,
                                              'decimal',
                                          )
                                        : '--'}
                                </div>
                                <div className={styles['secondary-content']}>
                                    <span>
                                        {
                                            generateElasticity(
                                                rowData.output.elasticity,
                                            )?.classification
                                        }
                                    </span>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={120} resizable>
                    <HeaderCell>Preço</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="price"
                    >
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>
                                    {formatNumbersToPtBr(
                                        rowData.output?.newPriceScenario?.price,
                                        'currency',
                                    )}
                                </div>
                                <div className={styles['secondary-content']}>
                                    <div>
                                        {formatNumbersToPtBr(
                                            rowData.output?.basePriceScenario
                                                ?.price,
                                            'currency',
                                        )}
                                    </div>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={120} resizable>
                    <HeaderCell>Margem</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="margin"
                    >
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div
                                    className={styles['main-content']}
                                >{`${formatNumbersToPtBr(rowData.output?.newPriceScenario?.margin, 'decimal')} %`}</div>
                                <div className={styles['secondary-content']}>
                                    <div>{`${formatNumbersToPtBr(rowData.output?.basePriceScenario?.margin, 'decimal')} %`}</div>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={120} resizable>
                    <HeaderCell>CPI</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="competitiveness"
                    >
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div
                                    className={styles['main-content']}
                                >{`${formatNumbersToPtBr(rowData.output?.newPriceScenario?.competitiveness, 'decimal')} %`}</div>
                                <div className={styles['secondary-content']}>
                                    <div>{`${formatNumbersToPtBr(rowData.output?.basePriceScenario?.competitiveness, 'decimal')} %`}</div>
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={180} resizable>
                    <HeaderCell>Vendas (un)</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="demandForecast"
                    >
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>
                                    {formatNumbersToPtBr(
                                        rowData.output?.newPriceScenario
                                            ?.demandForecast,
                                        'decimal',
                                    )}
                                </div>
                                <div className={styles['secondary-content']}>
                                    <span>
                                        {formatNumbersToPtBr(
                                            rowData.output?.basePriceScenario
                                                ?.demandForecast,
                                            'decimal',
                                        )}
                                    </span>
                                    <Highlight
                                        value={
                                            rowData.output?.scenariosVariation
                                                ?.demandForecast
                                        }
                                    />
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={180} resizable>
                    <HeaderCell>Receita (R$)</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="revenueForecast"
                    >
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>
                                    R${' '}
                                    {formatNumbersToPtBr(
                                        rowData.output?.newPriceScenario
                                            ?.revenueForecast,
                                        'decimal',
                                    )}
                                </div>
                                <div className={styles['secondary-content']}>
                                    R${' '}
                                    <span>
                                        {formatNumbersToPtBr(
                                            rowData.output?.basePriceScenario
                                                ?.revenueForecast,
                                            'decimal',
                                        )}
                                    </span>
                                    <Highlight
                                        value={
                                            rowData.output?.scenariosVariation
                                                ?.revenueForecast
                                        }
                                    />
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={180} resizable>
                    <HeaderCell>Lucro (R$)</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="profitForecast"
                    >
                        {(rowData: Simulation) => (
                            <CellWrapper className={styles['cell-wrapper']}>
                                <div className={styles['main-content']}>
                                    R${' '}
                                    {formatNumbersToPtBr(
                                        rowData.output?.newPriceScenario
                                            ?.profitForecast,
                                        'decimal',
                                    )}
                                </div>
                                <div className={styles['secondary-content']}>
                                    R${' '}
                                    <span>
                                        {formatNumbersToPtBr(
                                            rowData.output?.basePriceScenario
                                                ?.profitForecast,
                                            'decimal',
                                        )}
                                    </span>
                                    <Highlight
                                        value={
                                            rowData.output?.scenariosVariation
                                                ?.profitForecast
                                        }
                                    />
                                </div>
                            </CellWrapper>
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                {/* <Column width={50}>
                    <HeaderCell />
                    <Cell>
                        {(rowData: Simulation) => (
                            <Button
                                // disabled={!rowData.checked}
                                className={styles['icon-button']}
                                onClick={() => {}}
                            >
                                <MdQueryStats />
                            </Button>
                        )}
                    </Cell>
                </Column> */}
            </InfoTable>
        </Layout.Section>
    );
};
