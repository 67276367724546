import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, CheckPicker, DateRangePicker } from 'rsuite';
import DateRangePickerPill from '../../../../../../../../components/DateRangePickerPill';
import { SelectPill } from '../../../../../../../../components/Filters';
import { useDebounce } from '../../../../../../../../hooks';
import { selectorDemandForecast } from '../../../../../../../../reducers/previsaoDemanda';
import { dateLocale } from '../../../../../../../ISA/InfoPanel/OnDemand/utils';
import { dateShortcutNextDays } from '../../FiltersArea/Filters';

import { useFiltersSavedScenarios } from '../../../hooks/useFiltersSavedScenarios';
import styles from './FiltersAreaSavedSimulations.module.scss';

export const FiltersAreaSavedSimulations = () => {
    const { beforeToday } = DateRangePicker;

    const [searchProduct, setSearchProduct] = useState('');

    const {
        listProducts,
        listStores,
        handleSetFilter,
        clearFilters,
        handleClearDatePicker,
        isListStoresLoading,
    } = useFiltersSavedScenarios();

    const { isLoading: isListProductsLoading } = useQuery({
        queryKey: ['demand-forecast-products-saved-scenarios', searchProduct],
        retry: true,
        keepPreviousData: true,
        queryFn: () => listProducts(searchProduct),
    });

    const refToDatePicker = useRef<{ handleClean: () => void }>();

    const debounce = useDebounce(500);

    const { filtersArea } = useSelector(selectorDemandForecast);
    const handleDateRangePicker = (value: Date[]) => {
        if (value[0] && value[1]) {
            handleSetFilter({
                name: 'dateRange',
                filterValue: value,
            });
        }
    };

    const onCleanAll = () => {
        refToDatePicker?.current?.handleClean();
        clearFilters();
    };

    return (
        <main className={styles.container}>
            <section className={styles['section-title']}>
                <span>Filtros</span>
                <span>Selecione os filtros para a pesquisa</span>
            </section>

            <div className={styles['section-filters-container']}>
                <section className={styles['section-filters']}>
                    <div>
                        <SelectPill
                            id="select-picker-pill"
                            placeholder="Produto"
                            name="produto"
                            filter={false}
                            value={filtersArea?.selectedFilters?.productId}
                            data={filtersArea?.list?.products}
                            onSelect={(value: any, item: any) => {
                                handleSetFilter({
                                    name: 'productId',
                                    filterValue: value,
                                    item,
                                });
                            }}
                            onSearch={(e) =>
                                debounce(() => setSearchProduct(e))
                            }
                            onClean={() => {
                                handleSetFilter({
                                    name: 'productId',
                                    filterValue: '',
                                });
                                setSearchProduct('');
                            }}
                            locale={{
                                noResultsText: isListProductsLoading
                                    ? 'Carregando produtos...'
                                    : 'Nenhum item encontrado',
                                searchPlaceholder: 'Pesquisar',
                            }}
                        />
                    </div>

                    <div>
                        <CheckPicker
                            id="select-picker-pill"
                            style={{ maxWidth: '300px' }}
                            cleanable
                            searchable
                            placeholder={'Lojas'}
                            type="select"
                            name="loja"
                            filter={false}
                            value={filtersArea?.selectedFilters.storesId}
                            data={filtersArea.list.stores}
                            onSelect={(value) => {
                                handleSetFilter({
                                    name: 'storesId',
                                    filterValue: value,
                                });
                            }}
                            onClean={() => {
                                handleSetFilter({
                                    name: 'storesId',
                                    filterValue: '',
                                });
                            }}
                            locale={{
                                noResultsText: isListStoresLoading
                                    ? 'Carregando lojas...'
                                    : 'Nenhum item encontrado',
                                searchPlaceholder: 'Pesquisar lojas',
                            }}
                            onSearch={(search) =>
                                debounce(() => listStores(search))
                            }
                        />
                    </div>

                    <div>
                        {/* @ts-ignore */}
                        <DateRangePickerPill
                            block
                            cleanable
                            classNameValue={
                                filtersArea.selectedFilters.dateRange.length
                                    ? 'date-range-has-value'
                                    : ''
                            }
                            renderValue={(value: Date[]) => (
                                <>
                                    <span>
                                        {format(value[0], 'dd/MM/yyyy')}
                                    </span>
                                    {' a '}
                                    <span>
                                        {format(value[1], 'dd/MM/yyyy')}
                                    </span>
                                </>
                            )}
                            value={filtersArea.selectedFilters.dateRange}
                            onChange={handleDateRangePicker}
                            onOk={handleDateRangePicker}
                            onClean={handleClearDatePicker}
                            appearance="default"
                            placeholder="Período"
                            format="DD/MM/YYYY"
                            locale={dateLocale}
                            ranges={dateShortcutNextDays}
                            disabledDate={beforeToday()}
                            ref={refToDatePicker}
                        />
                    </div>
                </section>

                <Button
                    className={styles['btn-clear-filters']}
                    onClick={onCleanAll}
                >
                    Limpar filtros
                </Button>
            </div>
        </main>
    );
};
